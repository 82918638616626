<template>
  <div class="wrap">
    <Title
      pageTitle="TAILORED T-SHIRTS"
      pageDiscrption="テイラーTシャツ追加購入"
      color="var(--color-tailor-light)"
    />
    <div class="container">
      <div class="discription">
        <p class="price">
          T-SHIRTS: 7,500円（税込）〜<br />
          LONG T-SHIRTS: 9,500円（税込）〜
          <!-- LONG T-SHIRTS: <span style="text-decoration: line-through; font-size: 1.3rem; padding-right: 1rem;">9,500円</span>7,500円（税込）〜
        </p> -->
        </p>
      </div>

      <div class="form-content">
        <h3 class="form-content__title heading-3">
          仕様に変更のないお客様はこちらから
        </h3>
        <form method="post" action="https://cart.ec-sites.jp/sp_step2/pc/pk00/">
          <div class="bodySelect">
            <div class="bodySelect__item">
              <input
                type="radio"
                name="es_option[]_type"
                id="repeat-tshirt"
                value="658887"
                checked=""
              />
              <label for="repeat-tshirt">T-SHIRTS</label>
            </div>
            <div class="bodySelect__item">
              <input
                type="radio"
                name="es_option[]_type"
                id="repeat-long"
                value="658888"
              />
              <label for="repeat-long">LONG T-SHIRTS（+2,000円）</label>
              <!-- <label for="repeat-long">LONG T-SHIRTS（0円）</label> -->
            </div>
          </div>
          <div class="submit-wrap">
            <div class="quanity">
              <span>枚数</span>
              <input type="number" name="es_item_qty" value="1" id="quanity" />
            </div>
            <div class="submit">
              <input type="hidden" name="es_charset" value="sjis" />
              <input type="hidden" name="es_item_id" value="47" />
              <input type="hidden" name="es_shop_id" value="4777" />
              <input type="hidden" name="es_stock_attr_flag" value="0" />
              <button
                type="submit"
                name="es_submit"
                value="カートに入れる"
                class="submit_btn"
              >
                カートに入れる
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="changeSpec">仕様を変更したいお客様は下記にお進みください</div>

      <form method="post" action="https://cart.ec-sites.jp/sp_step2/pc/pk00/">
        <div class="form-content">
          <h3 class="form-content__title heading-3">ボディタイプ</h3>
          <div class="form-content__select">
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_option[]_type"
                id="tshirt"
                value="379438"
                checked=""
              />
              <label class="tshirt" for="tshirt"
                ><span class="name">T-SHIRTS</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_option[]_type"
                id="long"
                value="379439"
              />
              <label class="long-tshirt" for="long"
                ><span class="name">LONG T-SHIRTS（+2,000円）</span></label
              >
              <!-- <label class="long-tshirt" for="long"
                ><span class="name">LONG T-SHIRTS（0円）</span></label
              > -->
            </div>
          </div>
        </div>

        <div class="next"></div>

        <div class="form-content">
          <h3 class="form-content__title heading-3">カラー</h3>
          <div class="form-content__select">
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_color"
                id="white"
                value="0"
                checked=""
              />
              <label class="color-white" for="white"
                ><span class="name">WHITE</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_color"
                id="gray"
                value="1"
              />
              <label class="color-gray" for="gray"
                ><span class="name">GRAY</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_color"
                id="navy"
                value="3"
              />
              <label class="color-navy" for="navy"
                ><span class="name">NAVY</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_color"
                id="black"
                value="2"
              />
              <label class="color-black" for="black"
                ><span class="name">BLACK</span></label
              >
            </div>
          </div>
        </div>

        <div class="next"></div>

        <div class="form-content">
          <h3 class="form-content__title heading-3">生地</h3>
          <div class="form-content__select">
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_fabric"
                id="light"
                value="0"
              />
              <label class="fabric" for="light">
                <div class="fabric__title name">
                  LIGHT<span class="oz">4.4oz</span>
                </div>
                <div class="fabric__feature">
                  目が細かくハリとコシがある薄手生地です。程良い透け感と薄地ならではの軽量感で暑い時期に快適に着用いただけます
                </div>
              </label>
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_fabric"
                id="medium"
                value="1"
                checked=""
              />
              <label class="fabric" for="medium">
                <div class="fabric__title name">
                  MEDIUM<span class="oz">6.0oz</span>
                </div>
                <div class="fabric__feature">
                  コーマ糸を使用した高品質でスタンダードな厚みの生地です。クセがないので場面や着こなしを気にせずにご着用いただけます
                </div>
              </label>
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_fabric"
                id="heavy"
                value="2"
              />
              <label class="fabric" for="heavy">
                <div class="fabric__title name">
                  HEAVY<span class="oz">7.6oz</span>
                </div>
                <div class="fabric__feature">
                  重量感のある厚手生地です。間隔を詰めて生地を編んでいるので、耐久性に優れ、厚手により肌透けがしにくいのが特徴です
                </div>
              </label>
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_fabric"
                id="smooth"
                value="3"
              />
              <label class="fabric" for="smooth">
                <div class="fabric__title name">
                  SMOOTH<span class="oz">5.5oz</span>
                </div>
                <div class="fabric__feature">
                  ソフトな風合いと適度な光沢が特徴的な生地です。光沢加工を施すことで、高級感と品のある仕上がりを実現しています
                </div>
              </label>
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_fabric"
                id="stretch"
                value="4"
              />
              <label class="fabric" for="stretch">
                <div class="fabric__title name">
                  STRETCH<span class="oz">5.9oz</span>
                </div>
                <div class="fabric__feature">
                  縦横に伸縮する非常に柔軟性のある生地です。フィットサイズで作っても圧迫感や動きにくさを感じることはありません
                </div>
              </label>
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_fabric"
                id="mira-wave"
                value="5"
              />
              <label class="fabric" for="mira-wave">
                <div class="fabric__title name">
                  MIRA WAVE<span class="oz">8.8oz</span>
                </div>
                <div class="fabric__feature">
                  抗菌処理を施し、速乾・接触冷感・UVカットを兼ね備えた生地です。シワがつかず、洗濯による縮みもほとんどありません
                </div>
              </label>
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_fabric"
                id="coolmax"
                value="6"
              />
              <label class="fabric" for="coolmax">
                <div class="fabric__title name">
                  COOLMAX<span class="oz">4.6oz</span>
                </div>
                <div class="fabric__feature">
                  吸収速乾に優れた素材です。涼しく快適な着心地がありますので、スポーツはもちろんアクティブな場面におすすめです
                </div>
              </label>
            </div>
          </div>
        </div>

        <div class="next"></div>

        <div class="form-content">
          <h3 class="form-content__title heading-3">ネック</h3>
          <div class="form-content__select">
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_neck"
                id="O"
                value="0"
                checked=""
              />
              <label class="neck-o neck" for="O"
                ><span class="name">O-NECK</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_neck"
                id="O-Deep"
                value="3"
              />
              <label class="neck-o-deep neck" for="O-Deep"
                ><span class="name">O-DEEP</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_neck"
                id="V"
                value="1"
              />
              <label class="neck-v neck" for="V"
                ><span class="name">V-NECK</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_neck"
                id="V-Deep"
                value="4"
              />
              <label class="neck-v-deep neck" for="V-Deep"
                ><span class="name">V-DEEP</span></label
              >
            </div>
          </div>
        </div>

        <div class="next"></div>

        <div class="form-content">
          <h3 class="form-content__title heading-3">カスタムサイズ</h3>
          <div class="form-content__select">
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_option[]_size"
                id="tailor"
                value="611420"
                checked=""
              />
              <label class="tailor" for="tailor"
                ><span class="name">TAILOR [前回と同じ]</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_option[]_size"
                id="relax"
                value="611421"
              />
              <label class="relax" for="relax"
                ><span class="name">RELAX [110%拡大]（+1,500円）</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_option[]_size"
                id="big"
                value="611422"
              />
              <label class="big" for="big"
                ><span class="name">BIG [120%拡大]（+2,000円）</span></label
              >
            </div>
          </div>
        </div>

        <div class="next"></div>

        <div class="form-content">
          <h3 class="form-content__title heading-3">ポケット</h3>
          <div class="form-content__select2">
            <div class="form-content__select2--item">
              <label class="pocket"></label>
            </div>
            <div class="form-content__select2--item">
              <input
                type="radio"
                name="es_option[]_pocket"
                id="noPocket"
                value="610667"
                checked=""
              />
              <label for="noPocket"><span class="name">無し</span></label>
              <input
                type="radio"
                name="es_option[]_pocket"
                id="Pocket"
                value="610668"
              />
              <label for="Pocket"
                ><span class="name">有り（+1,000円）</span></label
              >
            </div>
          </div>
        </div>

        <div class="next"></div>

        <div class="form-content">
          <h3 class="form-content__title heading-3">袖口リブ</h3>
          <div class="form-content__select">
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_option[]_listlib"
                id="noLib"
                value="610669"
                checked=""
              />
              <label class="noLib sleeve-lib" for="noLib"
                ><span class="name">リブなし</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_option[]_listlib"
                id="addLib"
                value="610670"
              />
              <label class="addLib sleeve-lib" for="addLib"
                ><span class="name">リブ付き（+1,000円）</span></label
              >
            </div>
          </div>
        </div>

        <div class="next"></div>

        <div class="form-content">
          <h3 class="form-content__title heading-3">お直しサービス</h3>
          <div class="form-content__select2">
            <div class="form-content__select2--item">
              <p>
                ご購入後に気になる箇所などありましたら無料で修正させていただきます。ご希望の方はショッピングカート内のコメント欄に具体的に修正内容をご記入ください。
              </p>
              <div class="note">
                <div class="note__title">注意</div>
                大幅な変更は修正希望以外の箇所に影響を及ぼし全体のバランスが崩れる恐れがありますので、±2cm程度の小規模の修正でお願い致します。大幅な変更をご希望の方は再度サンプル付きのテイラーTシャツからご注文ください。
              </div>
            </div>
            <div class="form-content__select2--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_fix"
                id="nofix"
                value="1"
                checked=""
              />
              <label for="nofix"><span class="name">希望しない</span></label>
              <input
                type="radio"
                name="es_simple_attr_sel[]_fix"
                id="fix"
                value="0"
              />
              <label for="fix"><span class="name">希望する</span></label>
            </div>
          </div>
        </div>

        <div class="submit-wrap">
          <div class="quanity">
            <span>枚数</span>
            <input type="number" name="es_item_qty" value="1" id="quanity" />
          </div>
          <div class="submit">
            <input type="hidden" name="es_charset" value="sjis" />
            <input type="hidden" name="es_item_id" value="10" />
            <input type="hidden" name="es_shop_id" value="4777" />
            <input type="hidden" name="es_stock_attr_flag" value="0" />
            <button
              type="submit"
              name="es_submit"
              value="カートに入れる"
              class="submit_btn"
            >
              カートに入れる
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import Title from '../components/Title.vue'
import SizeChart from '../components/SizeChart.vue'

export default {
  components: { Title, SizeChart },
  setup() {
    const isActive = ref(true)
    const byBodySize = () => {
      isActive.value = true
    }
    const byTshirtSize = () => {
      isActive.value = false
    }
    return { isActive, byBodySize, byTshirtSize }
  },
}
</script>

<style lang="scss" scoped>
.discription {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6rem;

  & p {
    text-align: center;
    line-height: 2;
  }

  & .price {
    font-family: 'objektiv-mk1', sans-serif;
  }
}

.form-content {
  width: 100%;
  background-color: #000;
  padding: 4rem;
  text-align: center;
  border-radius: 10px;

  @media screen and (max-width: 425px) {
    padding: 2rem;
  }

  &__title {
    margin-bottom: 4rem;

    @media screen and (max-width: 425px) {
      margin-top: 1.5rem;
      margin-bottom: 3.5rem;
    }
  }

  &__select {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 2rem;

    &--item {
      display: flex;
      flex-direction: column;

      & input {
        display: none;
      }

      & input:checked + label {
        border: 3px solid #fff;
        opacity: 1;
      }

      & label {
        width: 100%;
        min-height: 100%;
        height: 350px;
        border: 3px solid #000;
        border-radius: 10px;
        background-size: cover;
        background-color: #000;
        background-position: center;
        transition: border 0.3s ease-out;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 2rem;
        font-family: 'objektiv-mk1', sans-serif;
        opacity: 0.8;

        & .name {
          background-color: var(--color-heavy);
          display: inline-block;
          padding: 5px 15px;
          font-size: 11px;
          font-family: 'objektiv-mk1', sans-serif;
          border-radius: 100px;
        }

        &.tshirt {
          background-image: url('../assets/img/bodyType-Tshirt.jpg');
        }
        &.long-tshirt {
          background-image: url('../assets/img/bodyType-LongTshirt.jpg');
        }
        &.color-white {
          background-image: url('../assets/img/white.jpg');
        }
        &.color-gray {
          background-image: url('../assets/img/gray.jpg');
        }
        &.color-navy {
          background-image: url('../assets/img/navy.jpg');
        }
        &.color-black {
          background-image: url('../assets/img/black.jpg');
        }
        &.neck-o {
          background-image: url('../assets/img/neck-O.jpg');
        }
        &.neck-v {
          background-image: url('../assets/img/neck-V.jpg');
        }
        &.neck-o-deep {
          background-image: url('../assets/img/neck-O-Deep.jpg');
        }
        &.neck-v-deep {
          background-image: url('../assets/img/neck-V-Deep.jpg');
        }
        &.tailor {
          background-image: url('../assets/img/custom-size-tailor.jpg');
        }
        &.relax {
          background-image: url('../assets/img/custom-size-relax.jpg');
        }
        &.big {
          background-image: url('../assets/img/custom-size-big.jpg');
        }
        &.noLib {
          background-image: url('../assets/img/lib-fold.jpg');
        }
        &.addLib {
          background-image: url('../assets/img/lib-attach.jpg');
        }
      }

      & .fabric {
        width: 100%;
        height: auto;
        padding: 3rem 2rem;
        justify-content: center;
        align-items: center;
        background-color: var(--color-dark);

        &__title {
          margin-bottom: 1rem;
          font-family: 'objektiv-mk1', sans-serif;
          font-weight: 700;
          display: flex;
          align-items: center;
          font-size: 16px;
          letter-spacing: 0.2rem;

          & .oz {
            font-size: 11px;
            margin-left: 5px;
          }
        }

        &__feature {
          font-size: 12px;
          line-height: 1.6;
          text-align: left;
        }
      }

      & .neck {
        height: 250px;
      }
    }
  }

  &__select2 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 2rem;

    &--item {
      display: flex;
      flex-direction: column;
      align-items: center;

      & input {
        display: none;
      }

      & input:checked + label {
        border: 1px solid #fff;
        background-color: #111;
        opacity: 1;
      }

      & label {
        border: 1px solid var(--color-dark);
        border-radius: 100px;
        background-color: #000;
        transition: border 0.3s ease-out;
        cursor: pointer;
        padding: 1rem;
        font-family: 'objektiv-mk1', sans-serif;
        margin-bottom: 2rem;
        width: 80%;

        @media screen and (max-width: 625px) {
          margin-bottom: 0;
          margin-top: 1.5rem;
        }

        &.pocket {
          background-image: url('../assets/img/pocket.jpg');
          background-size: cover;
          background-position: center;
          height: 250px;
          border-radius: 10px;
          margin-bottom: 0;
          border: none;
          width: 100%;

          @media screen and (max-width: 625px) {
            margin-top: 0;
          }
        }
      }
      & .note {
        font-size: 12px;
        text-align: left;
        padding: 1.5rem 2rem;
        border: 1px solid var(--color-dark);
        background-color: #111;
        margin-top: 1rem;
        line-height: 1.6;

        &__title {
          margin-bottom: 0.5rem;
          font-size: 12px;
        }
      }
    }
  }
}

.next {
  width: 1px;
  height: 70px;
  background-color: var(--color-dark);
  margin: 10px auto;
}

.info-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0 5rem;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
  }

  & input {
    display: none;
  }

  & input:checked + label {
    border: 1px solid var(--color-light);
    background-color: #1a1a1a;
  }

  &__item {
    width: 300px;
    margin: 0 10px;
    color: var(--color-light);
    padding: 15px 20px;
    border: 1px solid #555;
    border-radius: 100px;
    font-size: 1.5rem;
    font-weight: 300;
    cursor: pointer;
    transition: border 0.3s ease-out;

    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  &__item:hover {
    border: 1px solid #aaa;
  }
}

.submit-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6rem 0 0;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  & .quanity {
    margin: 0;
    display: flex;
    align-items: center;

    @media screen and (max-width: 600px) {
      margin-bottom: 3rem;
    }

    & input {
      background-color: #222;
      border: 1px solid var(--color-dark);
      color: var(--color-light);
      padding: 1.8rem 2rem;
      margin: 0 3rem 0 1rem;
      width: 120px;
      text-align: center;
      font-size: 18px;
      outline: none;
    }
  }

  & .submit {
    & button {
      color: var(--color-light);
      width: 300px;
      background-color: var(--color-tailor-light);
      padding: 15px 20px;
      border: none;
      border-radius: 100px;
      font-size: 1.5rem;
      text-align: center;
      font-weight: 300;
      cursor: pointer;
      transition: all 0.3s ease-out;

      &:hover {
        background-color: var(--color-tailor-dark);
      }
    }
  }
}

.bodySelect {
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  &__item {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    & input {
      display: none;
    }

    & input:checked + label {
      border: 1px solid #fff;
      background-color: #111;
    }

    & label {
      width: 300px;
      display: block;
      border: 1px solid var(--color-dark);
      border-radius: 100px;
      background-color: #000;
      transition: border 0.3s ease-out;
      cursor: pointer;
      padding: 1.5rem;
      font-family: 'objektiv-mk1', sans-serif;
      margin: 0 1rem;
    }
  }
}

.changeSpec {
  text-align: center;
  margin: 10rem 0 5rem;
}
</style>
